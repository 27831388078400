$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

    /**
    * PLACE YOUR OWN SCRIPTS HERE
    */
   
   // Main Slider
   
    $('#mainSlider').slick({
        dots:true,
        autoplay:true
    });

    // footer Slider
    $('#footerSlider').slick({
        dots:false,
        arrows:false,
        slidesToShow:5,
        slidesToScroll:1,
        autoplay:true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow:1 
                }
            }
        ]
    });

    // Navigation effect

    if ($(window).width() >= 1620) {

        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > 1) {
                $('.page-navi').addClass('fixed');
                $('.branding').addClass('fixed');
            }
            else {
                $('.page-navi').removeClass('fixed');
                $('.branding').removeClass('fixed');
            }
        });
     }

});
/////////////// END DOC READY